import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Card, Form, Input, Button } from 'antd';
import img from '../imgs/front_of_house.png';
import '../stylez/LandingPage.css'
import { submitData } from '../functions/submit';

const LandingPage = () => {
  const [submitted, setSubmitted] = useState(false);  // State to track submission status
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTextBreakPoint = useMediaQuery({ maxWidth: 475 });

  const handleSubmit = async (values) => {
    try {
      // Now we're waiting for the submitData promise to resolve before continuing
      await submitData(values.email, values.name);
      console.log('Received values of form: ', values);
      setSubmitted(true); // Update state to indicate success
    } catch (error) {
      // Handle any errors here, such as showing an error message to the user
      console.error('Submission failed:', error);
    }
  };
  


  const cardStyle = {
    width: isMobile ? '50%' : '34%', // 50% width on screens <= 600px, otherwise 34%
    backgroundColor: '#f0f2f5',
    // Add any other styles you want to apply to the Card
  };


  return (
    <div className="landing-page" style={{ backgroundImage: `url(${img})` }}>

      {/* Header Text */}
      <h1 className={`header-text${isTextBreakPoint ? '-475' : ''}`} data-aos="fade-down">Welcome to Mary B. Yancey House</h1>
      {/* <h2 className="tagline-text" data-aos="fade-down">Discover History's Charm — Mary B. Yancey House: Revealing Soon. Stay Updated!</h2> */}
      <h3 className={`tagline-text${isTextBreakPoint ? '-475' : ''}`} data-aos="fade-in" data-aos-duration="1000" data-aos-delay="1500">Discover History's Charm — Mary B. Yancey House: Revealing Soon. Stay Updated!</h3>

      {/* Email Signup Form inside a Card */}
      <Card style={cardStyle}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
            data-aos-easing="ease-in-out">
        {/* If the form has been submitted, display a success message */}
        {submitted ? (  // Conditional rendering based on the submitted state
          <p className="success-message">Thank you for subscribing!</p>
        ) : (
          <>
        <p className="form-header-text">Subscribe Below</p>

        <Form onFinish={handleSubmit}>
          <Form.Item style={{paddingBottom:'10px'}} name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }, {type: 'email', message: 'The input is not a valid email!',}]}>
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item style={{paddingTop:'4px'}}>
            <Button type="primary" htmlType="submit">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
          </>)}
      </Card>
    </div>
  );
}
      

export default LandingPage;
