import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';   // AOS styles
// import 'antd/dist/antd.css'; // Ant Design styles
import './App.css';          // Your custom styles
import LandingPage from './pages/LandingPage';

// import LandingPage from './components/LandingPage';

// Initializing AOS for animations
AOS.init();

const App = () => {
  return (
    <div className="App">
      {/* LandingPage component contains all the content */}
      <LandingPage />
    </div>
  );
}

export default App;
