export async function submitData(email, name) {
    const APIKEY = 'oLMp_lYzvol1eCWumWgOsB6c8I8uRSZu4CrF0uZw16U';
    const response = await fetch('http://127.0.0.1:5000/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': APIKEY,  // Include the API key in the request headers
      },
      body: JSON.stringify({ email, name }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    const data = await response.json();
    console.log(data);
}
